









































import { api } from "@/api/api";
import { ApiGetWorkingHourDto } from "@/api/generated/Api";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper, onParamChange } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, ref } from "@vue/composition-api";
import { WorkingHourStatusEnum } from "@/shared/enums/WorkingHourStatus.enum";

export default defineComponent({
  name: "CourseWorkingHoursPage",
  components: { BaseTableFiltered, BaseTooltipIconButton },
  setup() {
    const store = useStore<StoreState>();
    const route = useRoute();
    const router = useRouter();

    const search = ref("");
    const workingHours = ref<ApiGetWorkingHourDto[]>([]);
    const selectedRows = ref<ApiGetWorkingHourDto[]>([]);

    const displayWorkingHourEntry = (id: string) => {
      router.push({
        name: SingleCourseRouteNames.CourseWorkingHourDetails,
        params: {
          id: route.params.id,
          hourEntryId: id,
        },
      });
    };

    const loadWorkingHours = async () => {
      const getWorkingHoursResponse = (await api.economy.getWorkingHoursAsync({ CourseId: +route.params.id })).data;
      workingHours.value = getWorkingHoursResponse.map((hourRegistered) => ({
        ...hourRegistered,
        isSelectable: hourRegistered.status === WorkingHourStatusEnum.NotApproved,
      }));
    };

    onParamChange(loadWorkingHours);

    const approveSelectedWorkingHourEntries = async () => {
      await globalLoadingWrapper({ blocking: true }, async () => {
        if (!selectedRows.value) {
          return;
        }
        const idsToBeApproved = selectedRows.value.map((hourRegistered) => hourRegistered.id);
        await api.economy.approveWorkingHourAsync(idsToBeApproved);
        openNotification(store, NotificationItemType.Success, "Arbeidstimer godkjent");
      });
      selectedRows.value = [];
      await loadWorkingHours();
    };

    return {
      headers,
      search,
      workingHours,
      selectedRows,
      formatLocalizedDate,
      displayWorkingHourEntry,
      approveSelectedWorkingHourEntries,
    };
  },
});

const headers = [
  { text: "Navn", value: "userFullName" },
  { text: "Kontrakt-ID", value: "courseContractId" },
  { text: "Type timer", value: "costTypeName" },
  { text: "Antall timer", value: "amount" },
  { text: "Dato", value: "date" },
  { text: "Status", value: "statusText" },
  { text: "Handlinger", value: "actions" },
];
