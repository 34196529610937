var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTableFiltered',{staticClass:"mt-3",attrs:{"headers":_vm.headers,"items":_vm.workingHours,"search":_vm.search,"item-key":"id","show-select":"","sort-by":"date","sort-desc":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"}},[_vm._v("Arbeidstimer")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk i arbeidstimer","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.selectedRows.length > 0)?_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Godkjenn valgte arbeidstimer","icon":"mdi-file-sign","dataCy":"sendButton"},on:{"handleClick":_vm.approveSelectedWorkingHourEntries}}):_vm._e()]},proxy:true},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"displayOrder"},on:{"click":function($event){return _vm.displayWorkingHourEntry(item.id)}}},'v-icon',attrs,false),on),[_vm._v("mdi-open-in-new")])]}}],null,true)},[_c('span',[_vm._v("Vis registrering")])])]}},{key:"item.date",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatLocalizedDate(value))+" ")]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})}
var staticRenderFns = []

export { render, staticRenderFns }